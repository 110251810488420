import React, { Fragment } from 'react';
import DOMPurify from 'isomorphic-dompurify';
import remove from 'lodash/remove';
import { url, properCase } from '@hp-us-store/hpstore-helpers';

import date from './date';
import memoize from './memoize';

const EMPTY_PRICE = { regularPrice: '', salePrice: '' };
const { parseFilters, getFilterString, parseUrl, getRelativePath, getAbsoluteRedirectPath } = url;
const CURRENCY_SYMBOL = {
    USD: '$',
};

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
    // set all elements owning target to target=_blank
    if ('target' in node && node.target) {
        node.setAttribute('target', '_blank');
        node.setAttribute('rel', 'noopener');
    }
});

const buildParams = (prefix, obj, add) => {
    let name, i, l, rbracket;
    rbracket = /\[\]$/;
    if (prefix.includes('facets')) {
        prefix = prefix.replace(':', '.');
    }
    if (obj instanceof Array) {
        for (i = 0, l = obj.length; i < l; i++) {
            if (rbracket.test(prefix)) {
                add(prefix, obj[i]);
            } else {
                buildParams(prefix + '[' + (typeof obj[i] === 'object' ? i : '') + ']', obj[i], add);
            }
        }
    } else if (typeof obj == 'object') {
        // Serialize object item.
        for (name in obj) {
            buildParams(prefix + '[' + name + ']', obj[name], add);
        }
    } else {
        // Serialize scalar item.
        add(prefix, obj);
    }
};

export const Helpers = {
    DEFAULT_IMAGE_POLICY: 'Png_Res',
    date,
    memoize,
    uuidv4: () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (Math.random() * 16) | 0,
                v = c == 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    },
    sortKeys: obj => {
        if (typeof obj != 'object') {
            return;
        }
        return Object.keys(obj)
            .sort()
            .reduce(function (r, k) {
                r[k] = obj[k];
                return r;
            }, {});
    },
    getUrlLength: (path, query) => {
        query = Object.keys(query)
            .map(q => {
                return `${q}=${query[q]}`;
            })
            .join('&');
        return `${path}?${query}`.length;
    },
    getCatEntryId: product => {
        const { itemId, catentryId, catEntryId, ceId, catEntryID, catid, productId } = product || {};
        return itemId || catentryId || catEntryId || ceId || catEntryID || catid || productId;
    },
    getPDPJumpId: (product, jumpInfo, section = 'product-tile') => {
        if (!jumpInfo) {
            return {};
        }
        const { slugKey, pg } = jumpInfo || {};
        const { sku = '', rank = {}, name = '' } = product || {};
        try {
            const jumpIdParts = [
                'ma',
                slugKey,
                section,
                pg,
                rank.recommended + 1,
                sku.split('#')[0],
                name.substring(0, 20),
            ].map(s => (typeof s === 'string' ? s.replace(/\s+|_+/g, '-') : s));
            return {
                jumpid: encodeURIComponent(jumpIdParts.join('_').toLowerCase()),
            };
        } catch (e) {
            console.error(e);
            return {};
        }
    },
    getCarepackDuration: carepackName => {
        if (!carepackName) {
            return '';
        }
        let durations = ['one', 'two', 'three', 'four', 'five', 'six'];
        //replace words with numbers
        for (let i = 0; i < durations.length; i++) {
            carepackName = carepackName.replace(new RegExp(`${durations[i]}`, 'ig'), i + 1);
        }
        return ((carepackName || '').toLowerCase().match(/\d+(?=[\-|\s]?year|[\-|\s]?yr)/gi) || [''])[0];
    },
    paramsToString: (a, allowNull = true, whiteList, disableEncoding) => {
        let prefix, s, add, name, r20, output;
        s = [];
        r20 = /%20/g;
        add = function (key, value) {
            // If value is a function, invoke it and return its value
            value = typeof value == 'function' ? value() : value == null && allowNull ? '' : value;
            if (value == null) {
                return;
            }
            // encodeURI exceptions
            if (key === 'color') {
                s[s.length] = encodeURIComponent(key) + '=' + value.toLowerCase();
            } else {
                s[s.length] = encodeURIComponent(key) + '=' + encodeURIComponent(value);
            }
        };
        if (a instanceof Array) {
            for (name in a) {
                (!whiteList || !!whiteList[name]) && add(name, a[name]);
            }
        } else {
            for (prefix in a) {
                (!whiteList || !!whiteList[prefix]) && buildParams(prefix, a[prefix], add);
            }
        }
        output = s.join('&');
        return !disableEncoding ? output.replace(r20, '+') : output;
    },
    addUrlParam: (url, params = {}, replaceExisiting) => {
        if (!url || url === 'null') {
            return url;
        }
        if (typeof params !== 'object' && params !== null) {
            throw new TypeError(
                'Invalid parameter "params" supplied to Helpers.addUrlParam(). Value must be an object (key/value dictionary).',
            );
        }
        var addParam = (url, param, value) => {
            if (!url || typeof url !== 'string' || value == null) {
                return url;
            }
            var newUrl;
            var urlParts = url.split('#');
            var hash = urlParts.length > 1 ? '#' + urlParts[1] : '';
            var baseUrl = urlParts[0];
            var separator = baseUrl.indexOf('?') === -1 ? '?' : '&';

            if (url.indexOf(param + '=') >= 0) {
                var prefix = url.substring(0, url.indexOf(param));
                var suffix = url.substring(url.indexOf(param));
                suffix = suffix.substring(suffix.indexOf('=') + 1);
                var [ogValue] = suffix.match(/[^\&]+/) || [];
                suffix = suffix.indexOf('&') >= 0 ? suffix.substring(suffix.indexOf('&')) : '';
                newUrl = prefix + param + '=' + (replaceExisiting ? value : ogValue) + suffix;
            } else {
                let newParam = separator + param + '=' + value;
                newUrl = baseUrl.replace(newParam, '');
                newUrl += newParam;
            }
            return newUrl + hash;
        };

        for (var p in params) {
            url = addParam(url, p, params[p]);
        }
        return url;
    },
    addUrlParamV2: (url, params = {}, replaceExisiting) => {
        if (!url) {
            return url;
        }
        //adds parameter to existing url string
        let urlParts = url.split('?');
        if (urlParts.length > 1) {
            let queryString = urlParts[1];
            let urlParams = new URLSearchParams(queryString);
            for (let key in params) {
                if (replaceExisiting) {
                    urlParams.delete(key);
                }
                urlParams.append(key, params[key]);
            }
            return urlParts[0] + '?' + urlParams.toString();
        } else {
            let urlParams = new URLSearchParams();
            for (let key in params) {
                urlParams.append(key, params[key]);
            }
            return urlParts[0] + '?' + urlParams.toString();
        }
    },
    getAbsoluteRedirectPath: (pathname, query) => {
        let host;
        if (typeof document !== 'undefined' && document.location) {
            host = document.location.host;
        } else {
            //on the server access env variable for domain
            host = process.env.DOMAIN;
        }
        return getAbsoluteRedirectPath(pathname, `https://${host}`, process.env.BASENAME, query);
    },
    getBasenameRelativePath: (to, basename = process.env.BASENAME) => {
        if (!to || !basename || !to.startsWith('/')) {
            return to;
        }
        return (typeof to === 'string' && to.startsWith(basename) ? to : `${basename}${to}`).replace(/\/+/gi, '/');
    },
    parseFilters,
    getFilterString,
    parseUrl,
    getRelativePath,
    getCookie(name) {
        try {
            var value = '; ' + document.cookie;
            var parts = value.split('; ' + name + '=');
            if (parts.length == 2) return parts.pop().split(';').shift();
        } catch (e) {
            return undefined;
        }
    },
    setCookie(cname, cvalue, exdays, extime, path = '/', domain) {
        var d = new Date();
        d.setTime(extime || d.getTime() + exdays * 24 * 60 * 60 * 1000);
        var expires = 'expires=' + d.toUTCString();
        document.cookie = cname + '=' + cvalue + ';' + expires + `;path=${path}${domain ? `;Domain=${domain}` : ''}`;
    },
    getAutoHideComponent: memoize(
        (slugInfo, activePG) => {
            const { components } = slugInfo || {};
            const { metaData } = activePG || {};
            const { autoHideTypeTabLevel } = metaData || {};
            const { autoHideSkus: autoHideSettings } = components || {};
            let autoHideSkus = { ...autoHideSettings };
            //add backwards compatibility to old 'disable' field
            autoHideSkus.autoHideType = autoHideTypeTabLevel || autoHideSkus.autoHideType || 'hideNoIROOS';
            autoHideSkus.disableAutoHide =
                slugInfo.previewMode || autoHideSkus.autoHideType === 'disableAutoHide' || autoHideSkus.disable;

            return autoHideSkus;
        },
        (slugInfo, activePG) => {
            return `${slugInfo && slugInfo.key}-${
                activePG && activePG.key && activePG.metaData && activePG.metaData.autoHideTypeTabLevel
            }`;
        },
    ),
    parseFormData: params => {
        var data = [];
        for (var k in params) {
            data.push(`${k}=${params[k]}`);
        }
        return data.join('&');
    },
    isAlphaNumeric: (string, allowSpaces) => {
        //returns true a string only consists alphanumeric values
        const regex = allowSpaces ? /^[a-z0-9\s]+$/i : /^[a-z0-9]+$/i;
        return regex.test(string);
    },
    //return inner html text
    getInnerText: function (htmlString) {
        try {
            if (!htmlString) return htmlString;
            if (!this.stringContainsHTML(htmlString)) {
                return htmlString.trim();
            }
            return htmlString.replace(/<[^>]*>/g, '').trim();
        } catch (e) {
            return htmlString.trim();
        }
    },
    stringContainsHTML: text => {
        if (!text || typeof text !== 'string') {
            return false;
        }
        return /<\/?[a-z][\s\S]*>/i.test(text);
    },
    decodeHtml: html => {
        if (typeof document === 'undefined') {
            return html;
        }
        const txt = document.createElement('TEXTAREA');
        if (!txt) {
            return '';
        }
        txt.innerHTML = html;
        return txt.value;
    },
    createMarkup: (
        html,
        sanitize = true,
        sanitizationOptions = { ADD_TAGS: ['style'], ADD_ATTR: ['target'], FORCE_BODY: true },
    ) => {
        if (html === undefined || !sanitize) {
            return { __html: html };
        }
        return { __html: DOMPurify.sanitize(html, sanitizationOptions) };
    },
    getProductCDNImages: memoize(
        function (product, imwidth = [570], limit) {
            let images = limit ? product.images.slice(0, limit) : product.images;
            const { defaultImages } = product;

            return images.map((image, i) => {
                if (!image.isAnnotated && image.type !== 'video') {
                    let primaryImage = image.cdn;
                    try {
                        if (defaultImages > 0 && image.altImages && image.altImages.length > 0) {
                            primaryImage = image.altImages[defaultImages - 1].lrg;
                        }
                    } catch (e) {
                        //catch an exceptions with alt images
                    }
                    let lrg = this.getCDNUrl(primaryImage, image.lrg, null);
                    return {
                        ...image,
                        lrg,
                        cdnMap: imwidth.reduce(
                            (r, w) => {
                                r[`width-${w}`] = this.getCDNUrl(primaryImage, image.lrg, w);
                                return r;
                            },
                            {
                                get: function (width) {
                                    return this[`width-${width}`] || lrg;
                                },
                            },
                        ),
                    };
                } else {
                    return image;
                }
            });
        },
        (product, imwidth, limit) =>
            product
                ? `${product.sku}${product.selectedColor}${product.defaultImages}${
                      product.images && product.images.length
                  }${imwidth}${limit}`
                : 'undefined',
    ),
    getCDNUrl: function (cdnUrl, defaultImg, imwidth = 275, impolicy = this.DEFAULT_IMAGE_POLICY) {
        let imgUrl = (cdnUrl || defaultImg || '').replace(/http:\/\//, 'https://');

        if (!imgUrl) {
            return imgUrl;
        }
        //TODO: workd around for widen image webp referebce
        const widenRegex = /\/(jpg|png)\//gi;
        if (imgUrl.includes('widen.net') && widenRegex.test(imgUrl)) {
            const webpUrl = imgUrl.replace(widenRegex, '/webp/');
            return this.addUrlParamV2(webpUrl, imwidth ? { w: imwidth } : {}, true);
        }

        //TODO: hacky since default policy only applies to ssl-product-image domain. Get akamai to set up the same for all domains
        if (imgUrl && impolicy === this.DEFAULT_IMAGE_POLICY && !imgUrl.includes('ssl-product-images')) {
            return this.addUrlParam(imgUrl, { imwidth, imdensity: 1 });
        }

        imgUrl = this.addUrlParam(imgUrl, { imwidth, imdensity: 1, impolicy });
        return imgUrl;
    },
    getFiltersResults: (buckets, bucketMap) => {
        let index = 0;
        let initialIndices = [];

        //This is needed incase if bucketMap is undefined
        //Used to remove cabob case from tags and categories
        const removeKabobCase = name => {
            //make sure it is string
            name = name + '';

            name = name.replace('-', ' ');
            name = name
                .split(' ')
                .map(s => s.charAt(0).toUpperCase() + s.substring(1))
                .join(' ');
            return name;
        };

        const facetValues = buckets.map(bucket => {
            let facet = {};
            facet._id = bucket.key;
            facet.value = {
                text: bucketMap && bucketMap[bucket.key] ? bucketMap[bucket.key] : removeKabobCase(bucket.key),
            };
            facet.displayName =
                bucketMap && bucketMap[bucket.key] ? bucketMap[bucket.key] : removeKabobCase(bucket.key);
            facet.type = 'text';
            facet.count = bucket.doc_count;
            initialIndices.push(index);
            index++;
            return facet;
        });

        return { facetValues, initialIndices };
    },
    updateFiltersResults: (filtersResults, prevFiltersResults) => {
        let newResults = Object.assign({}, filtersResults);
        let prevInitials = {};

        prevFiltersResults.facets.forEach(facet => {
            prevInitials[facet._id] = { initialIndices: facet.initialIndices, facetValues: facet.facetValues };
        });

        newResults.facets.forEach(facet => {
            if (prevInitials[facet._id]) {
                facet.initialIndices = prevInitials[facet._id].initialIndices;
                facet.facetValues = prevInitials[facet._id].facetValues;
            } else {
                let initialIndices = [];
                let { facetValues } = facet;
                if (facetValues) {
                    facetValues.forEach((value, i) => {
                        if (value.count > 0) initialIndices.push(i);
                    });
                }
                facet.initialIndices = initialIndices;
            }
        });

        return newResults;
    },
    formatFiltersResults: (facets, query = {}) => {
        let esResult = {};
        esResult.facets = facets;

        esResult.facets.forEach(function (fk) {
            const keyPair = ['facets', fk.key].join(':');
            const fkSV = (query[keyPair] || '').split(',');
            fk.facetValues.forEach(function (fv) {
                var b =
                    fk.aggregations.find(function (b, i) {
                        return (b.key || i) === fv._id;
                    }) || {};
                fv.count = b.doc_count || 0;

                if (fkSV && fkSV.indexOf(String(fv._id)) > -1) {
                    fv.selected = true;
                } else if (fv.selected) {
                    delete fv.selected;
                }
            });
        });

        return esResult;
    },
    getProductPrice: function (product, prices, returnEmptyPriceObj = true) {
        if (!product) {
            return EMPTY_PRICE;
        }
        let { sku } = product;
        const prdClass = product.prdClass || product.product_type;
        const catId = this.getCatEntryId(product);
        if (sku && prdClass === 'PRT') {
            //for parent products. there is no record in ETR, remove P_ to match a child product
            //TODO: figure out a better way to get parent product price
            sku = sku.replace(/^P_/i, '');
        }
        let price = prices[sku] || prices[catId];

        const triedFetchingAndFailed =
            typeof price === 'object' && Object.values(price).length === 1 && price.priceFetchFailed;
        const justNoPricingInfo = !price;
        if (triedFetchingAndFailed) {
            price = {
                regularPrice: product.listPrice || '',
                salePrice: product.salePrice || '',
                priceFetchFailed: true,
            };
        } else if (justNoPricingInfo && returnEmptyPriceObj) {
            price = EMPTY_PRICE;
        }

        return price;
    },
    getPriceDiscount: price => {
        try {
            const regularPrice = price.regularPrice.toString().replace(/[^\d.-]/g, '') * 1;
            const salePrice = price.salePrice.toString().replace(/[^\d.-]/g, '') * 1;
            let discount =
                typeof price.priceDifference === 'number'
                    ? price.priceDifference
                    : Math.max(0, (regularPrice - salePrice).toFixed(4) * 1);
            if (discount < 0.9) {
                discount = Math.floor(discount);
            }
            discount = !isNaN(discount) ? discount : 0;
            const discountPercent = discount > 0 ? ((discount / regularPrice) * 100).toFixed(4) * 1 : 0;
            return {
                discount,
                discountPercent,
            };
        } catch (e) {
            return { discount: 0, discountPercent: 0 };
        }
    },
    setTemplatedPriceValues: (displayMsg, discount, discountPercent) => {
        //remove decimal points if discount is a whole number
        return displayMsg
            .replace(/({\$})|({%})/g, match => {
                if (match === '{%}' && discountPercent) {
                    const discountWholeNumber =
                        discountPercent % 1 === 0 ? Math.floor(discountPercent) : discountPercent;
                    return discount > 0 ? `${Math.floor(discountWholeNumber)}%` : '';
                } else if (match === '{$}') {
                    const discountWholeNumber = discount % 1 === 0 ? Math.floor(discount) : discount;
                    return discount > 0 ? `$${discountWholeNumber}` : '';
                }
            })
            .trim();
    },
    setDynamicProductValues(displayMsg, price, limit, unitsLeft, savingsPrefix = 'Save ') {
        try {
            const { violatorMessage } = price || {};
            //if no message provided and there is a violatorMessage provided by the price. use it.
            if (!displayMsg && violatorMessage) {
                return violatorMessage;
            }
            //match price or percentage discount in message
            displayMsg = displayMsg
                .replace(/({\$})|({%})/g, match => {
                    const { discount, discountPercent } = this.getPriceDiscount(price);
                    if (match === '{%}') {
                        return discount > 0 ? `${savingsPrefix}${Math.floor(discountPercent)}%` : '';
                    } else if (match === '{$}') {
                        return discount > 0 ? `${savingsPrefix}$${Math.floor(discount)}` : '';
                    }
                })
                .trim();
            if (limit != undefined) {
                //match limit
                displayMsg = stringReplace(displayMsg, new RegExp(/\{limit}/g), (match, i, offset) => {
                    if (limit === -1) {
                        invalidMatch = true;
                    }
                    return (
                        <React.Fragment key={`redemption-limit-${i}`}>
                            <span className="redemption-limit">{limit}</span>
                            {match}
                        </React.Fragment>
                    );
                });
            }
            if (unitsLeft != undefined) {
                //match unitsLeft
                displayMsg = stringReplace(displayMsg, new RegExp(/\{unitsLeft}/g), (match, i, offset) => {
                    if (unitsLeft === -1) {
                        invalidMatch = true;
                    }
                    return (
                        <React.Fragment key={`units-left-${i}`}>
                            <span className="units-left">{unitsLeft}</span>
                            {match}
                        </React.Fragment>
                    );
                });
            }
        } catch (e) {}
        return displayMsg;
    },
    getSearch: (search, lowerCaseKey = false) => {
        if (typeof search === 'object') {
            //handle null which is an object
            return search || {};
        }
        let searchObj = {};
        try {
            const pairs = search[0] === '?' ? search.slice(1).split('&') : search.split('&');
            pairs.reduce((result, pair) => {
                let [key = '', value] = pair.split('=');
                key = lowerCaseKey ? key.toLowerCase() : key;
                //do not allow null query keys
                if (key) {
                    result[key] = decodeURIComponent(value || '');
                }
                return result;
            }, searchObj);
        } catch (e) {
            return {};
        }

        return searchObj;
    },
    parsePreviewParam: _preview => {
        //parse special params
        if (_preview) {
            let [prefix, page, date] = atob(decodeURIComponent(_preview)).split('|');
            return {
                page,
                date: date * 1,
            };
        } else {
            return _preview;
        }
    },
    convertPriceToNumber: (str, currencySymbol = '$') => {
        if (typeof str !== 'string') {
            return str;
        }

        return str.replace(currencySymbol, '').replace(/[,|\(|\)]/gi, '') * 1;
    },
    formatCurrency: (value, currencySymbol = '$', valueOnly, noDecimal = false, currencySuffix) => {
        if (!value && value !== 0) {
            return '';
        }
        if (currencySymbol in CURRENCY_SYMBOL) {
            currencySymbol = CURRENCY_SYMBOL[currencySymbol];
        }
        try {
            value = typeof value === 'string' ? value.replace(/[^\d.-]/g, '') * 1 : value;

            if (valueOnly && value?.toFixed) {
                let v =
                    currencySymbol +
                    value.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') +
                    (currencySuffix || '');
                return noDecimal ? v.split('.')[0] : v;
            }

            const price = value.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
            const [dollarAmount, centsAmount] = price.split('.');

            return (
                <Fragment>
                    <span className="currency-symbol">{currencySymbol}</span>
                    <span>{dollarAmount + '.'}</span>
                    <span className="cent-amount">{centsAmount}</span>
                    {currencySuffix}
                </Fragment>
            );
        } catch (e) {
            return value;
        }
    },
    hasShippingOffer(product) {
        const { attributes = {}, offers = [] } = product;
        const hasShippingOffer =
            offers.filter(offer => {
                let offerText = typeof offer === 'object' ? offer.value : offer || '';
                return /free.*(next business day)/i.test(offerText);
            }).length > 0;
        if (attributes.plcode) {
            const subCat = this.getMappedSubCat(attributes.plcode);
            if (
                (subCat === 'toner' || subCat === 'ink') &&
                hasShippingOffer &&
                !attributes.pm_product.match(/^.*(Instant Ink).*$/gi)
            ) {
                return true;
            }
        }
        return false;
    },
    isSubscription: cartItem => {
        return (
            (cartItem.isSubscriptionSKU || '').toLowerCase() === 'yes' ||
            (cartItem.STOSKUType || '').toLowerCase() === 'subscription'
        );
    },
    getSubscriptionPricePer: subFrequency => {
        if (subFrequency) {
            switch (subFrequency) {
                case 'Annual':
                    return 'year';
                case 'Monthly':
                    return 'month';
            }
        }
        return null;
    },
    getSubFrequencyText: function (subFrequency) {
        if (!subFrequency) {
            return null;
        }
        return ` /${this.getSubscriptionPricePer(subFrequency)}`;
    },
    removeSKULocalization: sku => {
        if (typeof sku !== 'string') {
            return sku;
        }
        const regex = /#/gi;
        return sku.split(regex)[0];
    },
    BVTransformSKU: function (str, removeLocalization = true) {
        if (typeof str !== 'string') {
            return str;
        }
        const regex = /#/gi;
        return removeLocalization ? this.removeSKULocalization(str) : str.replace(regex, '_BVEP_');
    },
    getMappedCat: (plCode = '') => {
        let cat = 'other';
        let catMap = {
            accessories: [
                '2a',
                '2h',
                '6a',
                '8w',
                '9f',
                '9g',
                '9h',
                'gt',
                'mp',
                '35',
                '52',
                '6h',
                '7a',
                '9t',
                'i5',
                'ut',
                /*old plcodes->*/ '8n',
                'ff',
                'kn',
                'tx',
            ],
            carepack: ['64', 'mn', 'r6', 'f2', 'k2', 'mg', /*old plcodes->*/ '2d', '7b', '9r', 'cy', 'r4', 'uw'],
            desktops: [
                '1m',
                '2c',
                '5u',
                '5x',
                '7f',
                'bq',
                'dg',
                'ga',
                'i0',
                'i1',
                'il',
                'ta',
                /*new nov 2020 ->*/ 'm5',
                /*old plcodes->*/ '6j',
                '6v',
            ],
            monitor: ['2g', 'bo', 'tb' /*old plcodes->*/, , '1u'],
            notebooks: ['2f', '2t', '6u', '8j', 'an', 'g7', 'kv', 'uv', 'ik', /*new nov 2020 ->*/ 'm6', 'm7', 'm8'],
            printers: [
                '1d',
                '2b',
                '2n',
                '2q',
                '30',
                '3y',
                '4h',
                '4x',
                '5m',
                '7t',
                '8a',
                'c5',
                'du',
                'gc',
                'gs',
                'if',
                'ig',
                'ir',
                'is',
                /**new nov 2020 -> */ 'l6',
                'l7',
                'l8',
                'l9',
                'le',
                'lg',
                'lo',
                'ls',
                'im',
                /*old plcodes ->*/ '4l',
                '4m',
                '83',
                '9c',
                'ak',
                'c2',
                'dl',
                'k5',
                'ly',
                'ma',
                'mc',
                'pq',
                'sb',
                'st',
                't2',
                't8',
            ],
            supplies: [
                '1n',
                'au',
                'gd',
                'gj',
                'gk',
                'gm',
                'gp',
                'k6',
                'uk',
                'ur',
                '65',
                'e5',
                'g0',
                'hf',
                'ud',
                /*new nov 2020 ->*/ 'lu',
                /*old plcodes ->*/ '5t',
                'gn',
            ],
            tablets: ['4t', '6x', '7v', 'ez', 'fd', 'fg'],
            giftcards: ['00'],
        };
        //temp fix for products that have dup attributes
        if (Array.isArray(plCode)) {
            plCode = plCode[0];
        }

        plCode = plCode.toLowerCase();
        for (let c in catMap) {
            if (catMap[c].indexOf(plCode) > -1) {
                cat = c;
                break;
            }
        }
        return cat;
    },
    getMappedSubCat: (plCode = '') => {
        let cat = 'other';
        let catMap = {
            '3pp': ['8w'],
            camera: ['kn'],
            desktopaccy: ['2h', '9f', '9h'],
            desktopcommercial: ['2c', '5u', '5x', '7f', 'dg', 'ga', 'i0'],
            desktopconsumer: ['1m', '6j', '6v'],
            ink: ['1n', 'gd', 'gm', 'k6', 'uk', 'ur'],
            inkjetprinter: ['2n', '3y', '4h', '5m', '7t', '83', 'c2', 'dl', 'du', 't8', 'gc', '30'],
            ipgaccy: ['2a', '65', '6a', 'gt', 'tx'],
            ipgesp: ['2d', '64', '7b', 'r4', 'r6', 'uw'],
            laserjetprinter: [
                '2b',
                '2q',
                '4l',
                '4m',
                '8a',
                '9c',
                'ak',
                'c5',
                'k5',
                'ly',
                'ma',
                'mc',
                'pq',
                'sb',
                'st',
                't2',
                'ir',
            ],
            media: ['au'],
            monitor: ['1u', '2g', 'bo', 'tb', 'ut'],
            notebookaccy: ['8n', '9g', 'ff', 'mp'],
            notebookcommercial: ['6u', '8j', 'an', 'g7', 'ta', 'uv'],
            notebookconsumer: ['2f', '2t', 'kv'],
            psgesp: ['9r', 'cy', 'mn'],
            tabletcategory: ['4t', '6x', '7v', '9t', 'ez', 'fd', 'fg'],
            toner: ['5t', 'gj', 'gk', 'gn', 'gp'],
        };
        //temp fix for products that have dup attributes
        if (Array.isArray(plCode)) {
            plCode = plCode[0];
        }

        plCode = plCode.toLowerCase();
        for (let c in catMap) {
            if (catMap[c].indexOf(plCode) > -1) {
                cat = c;
                break;
            }
        }
        return cat;
    },
    pick: (obj, paths) => {
        return Object.assign({}, ...paths.map(prop => ({ [prop]: obj[prop] })));
    },
    pickBy: (obj, predicate) => {
        return Object.keys(obj).reduce((r, key) => {
            if (predicate(key)) {
                r[key] = obj[key];
            }
            return r;
        }, {});
    },
    isCarePack: product => {
        try {
            const { attributes, pm_category } = product || {};
            let { pm_category: attrPMCat = '' } = attributes || {};
            return (pm_category || attrPMCat) === 'HP Care Pack Services';
        } catch (e) {
            return false;
        }
    },
    isECarepack: product => {
        try {
            const { eCarePack, attributes, pm_category, productBadge, productType, carepacktype, subType } =
                product || {};
            let { carepacktype: attrCarepackType = '' } = attributes || {};
            const isECarePack =
                pm_category === 'HP Care Pack Services' && [productType, productBadge].includes('DIGITAL');
            //handle stupid duplicate attributes in feed
            const carepackType =
                carepacktype || (Array.isArray(attrCarepackType) ? attrCarepackType[0] : attrCarepackType);

            return (
                eCarePack ||
                (carepackType && carepackType.toLowerCase() === 'electronic') ||
                isECarePack ||
                'VCP' === subType
            );
        } catch (e) {
            return false;
        }
    },
    isGiftCard: product => {
        const { prdClass = '', product_type = '', pType = '', productType: priceProductType } = product;
        const productType = prdClass || product_type || pType;
        return ['pgc', 'vgc'].includes(productType.toLowerCase()) || priceProductType === 'giftcard';
    },
    isProductOffline: price => {
        if (price.regularPrice === '' && price.salePrice === '') {
            return true;
        } else {
            return false;
        }
    },
    footnoteRegex: () => {
        return new RegExp(/\[(\d{1,2}[\,\d{1,2}]*)\]/);
    },
    isProductPC(product) {
        try {
            const { attributes = {} } = product;
            const { plcode = '' } = attributes;
            const mappedCat = this.getMappedCat(plcode).toLowerCase();
            return ['desktops', 'notebooks'].includes(mappedCat);
        } catch (e) {
            return false;
        }
    },
    getPrinterType(product) {
        const { attributes = {}, name = '' } = product || {};
        const { plcode = '' } = attributes;
        const inkRegex = /(inkjet|deskjet|envy|officejet|pagewide|designjet|sprocket)(.*printer)|tango/;
        const laserRegex = /(laserjet)(.*printer)/;
        if (this.getMappedSubCat(plcode).toLowerCase() === 'inkjetprinter' || name.toLowerCase().match(inkRegex)) {
            return 'ink';
        } else if (
            this.getMappedSubCat(plcode).toLowerCase() === 'laserjetprinter' ||
            name.toLowerCase().match(laserRegex)
        ) {
            return 'laser';
        }
        return;
    },
    getGiftCardParentSkuFromChildSku(product) {
        const { sku } = product;
        try {
            return !/^P_/.test(sku) ? `P_${sku.replace(/_@[a-zA-Z0-9]*$/, '')}` : sku;
        } catch (e) {}
        return sku;
    },
    validateEmail(email) {
        var re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    },
    overrideDefaultSortOrder(products, originalRank, newRank) {
        //override recommended with the value of slp rank and sort
        //if no new rank provided offset to the end
        if (!products) {
            return;
        }

        let sortedProducts = products.slice(0);
        let udfInd = [];
        let udfCards = remove(sortedProducts, (product, i) => {
            if (product.prdClass == 'USER_DEFINED') {
                udfInd.push(i);
                return true;
            }
            return false;
        });
        sortedProducts.sort((a, b) => {
            let p2Metric, p1Metric;
            if (a.rank[newRank] === undefined) {
                a.rank[newRank] = 9999 + a.rank[originalRank];
            }
            if (b.rank[newRank] === undefined) {
                b.rank[newRank] = 9999 + b.rank[originalRank];
            }

            a.rank[originalRank] = a.rank[newRank];
            b.rank[originalRank] = b.rank[newRank];
            p1Metric = a.rank[originalRank];
            p2Metric = b.rank[originalRank];

            if (p1Metric < p2Metric) return -1;
            if (p1Metric > p2Metric) return 1;
            return 0;
        });
        udfCards.forEach((card, i) => {
            sortedProducts.splice(udfInd[i], 0, card);
        });
        return sortedProducts;
    },
    hpUtilTrackEvent({ base, omni, prefix, errorMessage }) {
        try {
            hpUtil.trackEvent({ base, omni, prefix });
        } catch (e) {
            console.log(`${errorMessage || ''}`, e);
        }
    },
    properCase,
    titleCase: str => {
        return str
            .split(' ')
            .map(str => {
                if (str.length == 0) return str.toUpperCase();
                return str[0].toUpperCase() + str.toLowerCase().substr(1);
            })
            .join(' ');
    },
    dedupArray: array => {
        try {
            //if not an array return it
            if (!Array.isArray(array)) {
                return array;
            }
            //depupe array of strings, using a hash
            let seen = {};
            return array.filter(item => {
                return seen.hasOwnProperty(item) ? false : (seen[item] = true);
            });
        } catch (e) {
            return array;
        }
    },
    parseHpServicesImages: function (priceObj, optimizeImage) {
        const that = this;
        let { fullImages, prodName } = priceObj || {};
        let annotatedImages = [];
        let allImages =
            fullImages &&
            typeof fullImages === 'object' &&
            Object.keys(fullImages)
                .sort()
                .reduce((allImages, key) => {
                    let imageUrl = fullImages[key];

                    let validImage = imageUrl && !/video/i.test(key);
                    if (!validImage) return allImages;
                    imageUrl = optimizeImage ? that.getCDNUrl(imageUrl) : imageUrl;
                    let isAnnotated = /annotated/i.test(key);
                    if (isAnnotated) {
                        annotatedImages.unshift({
                            src: imageUrl,
                            alt: prodName,
                        });
                    } else {
                        allImages.push({
                            src: imageUrl,
                            alt: prodName,
                        });
                    }

                    return allImages;
                }, []);

        return allImages && allImages.length > 0
            ? allImages
            : annotatedImages && annotatedImages.length > 0
              ? annotatedImages
              : [{ src: null }];
    },
    getCaseInsensitiveQueryStringValue: (queryString, queryStringKey) => {
        try {
            let queryStringPart = queryString.match(new RegExp(`${queryStringKey}=[^&$#]+`, 'i'))[0];
            return queryStringPart.length && queryStringPart.indexOf('=') > 0 ? queryStringPart.split('=').pop() : null;
        } catch (e) {}
        return null;
    },
    mergeQueryStrings: (url, queryString) => {
        if (!queryString || !url) {
            return url;
        }
        const [path, existingQuery = ''] = url.split('?');
        const [qq, newQuery = ''] = queryString.split('?');
        const mergedQuery = `${existingQuery}&${newQuery}`.replace(/^\&/, '').replace(/\&$/, '');

        return `${path}${mergedQuery ? `?${mergedQuery}` : ''}`;
    },
    isValidHpUrl: url =>
        typeof url === 'string' && /^(https?\:\/\/)?(www\.)?([a-zA-Z0-9\.\-]+\.)?(hp\.com|hpicloud\.net)/i.test(url),
    sanitizeUrlString: function (url) {
        return url.replace(/[\<\>\'\"\:;!$\^]/g, char => {
            if (["'", '"'].includes(char)) {
                return escape(char);
            }
            return encodeURIComponent(char);
        });
    },
    sanitizeUrl: function (url) {
        try {
            let protocolRegex = /^https?\:\/\//i;
            let hasProtocol = protocolRegex.test(url);
            let protocol = hasProtocol && url.split('//')[0] + '//';
            let domainAndPath = hasProtocol && url.split('//').pop();
            if (protocol && domainAndPath) {
                return `${protocol}${this.sanitizeUrlString(domainAndPath)}`;
            }

            return this.sanitizeUrlString(url);
        } catch (e) {}

        return url;
    },
    purifyUrl: function (url) {
        try {
            return DOMPurify.sanitize(url);
        } catch (e) {
            return url;
        }
    },
    attemptFunc: callback => {
        try {
            callback();
        } catch (e) {}
    },
    retryFunc: (fn, options = {}) => {
        const { retries = 3, interval = 500, exponentialBackoff = true } = options;
        return new Promise((resolve, reject) => {
            fn()
                .then(resolve)
                .catch(err => {
                    setTimeout(() => {
                        if (retries === 1) {
                            reject(err);
                            return;
                        }
                        Helpers.retryFunc(fn, {
                            retries: retries - 1,
                            interval: exponentialBackoff ? interval * 2 : interval,
                        }).then(resolve, reject);
                    }, interval);
                });
        });
    },
    /** help map different formats of color names to colorMap key value store*/
    getHexColor: (color, colorMap = {}) => {
        const key = color.trim().toLowerCase().replace(/\s/gi, '');
        const keyAlt = color.trim().toLowerCase().replace(/\s/gi, '+');
        const keyAlt2 = color
            .trim()
            .toLowerCase()
            .replace(/[\s|\+]/gi, '');
        //default to gray if no color mapping available
        return (colorMap[key] || colorMap[keyAlt] || colorMap[keyAlt2] || '#808080').split(',');
    },
    shouldNotIndex(storeEnvironment, query, robots, previewMode, proxyHost) {
        let { _seo } = query || {};
        return (
            (storeEnvironment !== 'production' && _seo !== 'debug') ||
            robots ||
            previewMode ||
            //if proxyHost is set and includes hpcloud.hp.com it should not be indexed
            (proxyHost && /https?:\/\/.*.hpcloud.hp.com/gi.test(proxyHost))
        );
    },
    saveLastScrollPosition: path => {
        try {
            sessionStorage.setItem(
                'lastScrollPosition',
                JSON.stringify({
                    path: path || document.location.pathname,
                    position: document.documentElement.scrollTop,
                }),
            );
        } catch (e) {}
    },
    getLastScrollPosition: () => {
        try {
            return JSON.parse(sessionStorage.getItem('lastScrollPosition'));
        } catch (e) {
            return {};
        }
    },
};

try {
    window.Helpers = window.Helpers || Helpers;
} catch (e) {}
