import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import debounce from 'lodash/debounce';

import { fetchHeaderFooterComponent, showPopover } from './header-footer-actions';
import Icon from './icon';
import HeaderMobile from './header-mobile';
import { Helpers } from '../../../core/src/helpers';
import withWindowResize from '../../../ui/components/window-resize';
import { withError } from '../../../shared/components/error-boundary';
import HeaderDesktop from './header-desktop';
import UtilityNavContainer from '../../../utility/components/utility-nav-container';
import Link from '../../../shared/components/hyperlink';
import Overlay from './overlay';
import AccessibleElement, { getNextInteractiveElement } from '../../../ui/components/accessible-element';
import ValueProp from '../value-prop/value-prop-v2';
import { getPStoreID } from '../../../hooks/useUserData';
import BotNav from './bot-nav';

import './css/header.less';

const SearchAutocompleteContainer = loadable(() =>
    Helpers.retryFunc(() => import('../../../search/components/search-autocomplete-container')),
);

const POPOVER_KEY = 'headerTabs';

const { memoize } = Helpers;

const getPrivateStoreHeader = memoize(
    (headerFooter, pStoreID) => {
        const { header } = headerFooter;
        const pStoreHeader = headerFooter[`header:${pStoreID}`];
        return pStoreHeader ? Object.assign(header, pStoreHeader) : header;
    },
    ({ header }, pStoreID) => `${Object.keys(header)}-${pStoreID}-${header && header.nav && header.nav.length}`,
);

function SkipLinks() {
    return (
        <ul className="skip-links" aria-label="Skip links">
            <li>
                <AccessibleElement element="a" className="screenReading js-skip-link" href="#pageContent" tabIndex={0}>
                    Skip to Content
                </AccessibleElement>
            </li>
            <li>
                <AccessibleElement element="a" className="screenReading js-skip-link" href="#footer" tabIndex={0}>
                    Skip to Footer
                </AccessibleElement>
            </li>
        </ul>
    );
}

const replaceShopLink = (nav, proxyHost, basename) => {
    if (!proxyHost || proxyHost?.includes('https://www.hp.com')) return nav;

    const shopTab = { name: 'Shop', categories: [] };
    const newNav = nav.map(tab => {
        if (tab.name === 'Shop') {
            const baseUrl = proxyHost + basename;
            shopTab.categories = tab.categories.map(cat => {
                const items = cat.items.map(item => {
                    //TODO: temp fix for pdp ab test, remove this and make prod consistent with proxyhost urls
                    if (item?.link?.includes('https://www.hp.com/')) {
                        return item;
                    }

                    //exceptions accessories & printers
                    if (item.name === 'Accessories') {
                        return { ...item, link: `${baseUrl}/cat/accessories-88342--1` };
                    }
                    if (item.name === 'Printers') {
                        return { ...item, link: `${baseUrl}/cat/printers` };
                    }
                    if (item.name === 'Track your order') return item;

                    return { ...item, link: baseUrl + item.link };
                });
                return { ...cat, items };
            });
            return shopTab;
        }
        return tab;
    });
    return newNav;
};

export class Header extends Component {
    static propTypes = {
        header: PropTypes.object.isRequired,
        fetchHeader: PropTypes.func.isRequired,
        device: PropTypes.string.isRequired,
        width: PropTypes.number,
        popover: PropTypes.bool.isRequired,
        showDropdown: PropTypes.func.isRequired,
    };

    static defaultProps = {
        width: 0,
    };

    containerRef = React.createRef();

    state = {
        openSuggestions: false,
        suggestions: null,
        searchKey: null,
        inputFocused: false,
        mounted: false,
        pstoreHeaderHtml: null,
        pstoreScriptError: false,
        isMobileDrawerOpen: false,
    };

    componentDidMount() {
        const { header, fetchHeader } = this.props;

        // TODO: need a better flag for if the data needs to be fetched
        if (!header.disabled && Object.keys(header.nav).length === 0) {
            fetchHeader();
        }
    }

    componentDidUpdate(prevProps) {
        const modalToggled = prevProps.openSearchModal !== this.props.openSearchModal;
        if (!modalToggled) {
            return;
        }

        try {
            if (this.props.openSearchModal) {
                return document.body.addEventListener('keyup', this.trapFocus);
            }
            document.body.removeEventListener('keyup', this.trapFocus);
        } catch (e) {}
    }

    getHomeLink = () => {
        const { header, pStoreID } = this.props;
        if (!pStoreID || ['hpepp', 'epp'].find(x => x === pStoreID.toLowerCase())) {
            return header.homeLogoLink || `${process.env.BASENAME}`;
        }
        return `${process.env.BASENAME}`;
    };

    updateSearchKey = debounce(searchKey => this.setState({ searchKey }), 500);

    setSearchKey = searchKey => {
        if (!this.state.inputFocused) {
            this.setState({ inputFocused: true });
        }

        this.updateSearchKey(searchKey);
    };

    setInputFocused = inputFocused => this.setState({ inputFocused });

    setMobileDrawerOpen = isMobileDrawerOpen => this.setState({ isMobileDrawerOpen });

    trapFocus = event => {
        const charCode = event.keyCode || event.which;
        if (charCode !== 9) return;
        try {
            if (!this.containerRef.current.contains(document.activeElement)) {
                const elem = getNextInteractiveElement('a,[tabindex]', this.containerRef.current);
                elem.focus();
            }
        } catch (e) {}
    };

    render() {
        const {
            header,
            width,
            device,
            popover,
            isOverlay,
            showDropdown,
            isV2,
            proxyHost,
            variation,
            basename,
            openSearchModal,
            privateStoreHeader,
            unifiedSearchDevice,
            isServerCalc,
        } = this.props;

        const { searchKey, inputFocused, isMobileDrawerOpen } = this.state;
        const { nav, disabled, customHTML, hideUtilityNAV } = header;
        const isDesktop = width > 768 || device === 'desktop';
        // if pstoreid is specified and still fetching components, dont render the GS headers yet until the components are retrieved.
        const pstoreHeaderCustomHtml = privateStoreHeader && privateStoreHeader.customHTML;
        const pstoreHeader = privateStoreHeader && privateStoreHeader[isDesktop ? 'desktop' : 'mobile'];
        const { nav: pstoreNav } = pstoreHeader || {};

        if (customHTML && !pstoreHeaderJson && !pstoreHeaderCustomHtml) {
            return <div dangerouslySetInnerHTML={Helpers.createMarkup(customHTML)} />;
        }

        // TODO: TEMP
        if ((disabled || header.nav.length === 0) && !privateStoreHeader) {
            return null;
        }

        //replace Shop links to use proxy host
        const newNav = replaceShopLink(nav, proxyHost, basename);

        // TODO: z-index fix revisit when classic header is removed
        const zIndex = isOverlay ? 1040 : null;

        const logoLink = this.getHomeLink();

        let headerProps = {
            nav: pstoreNav || newNav,
            onHover: this.onHover,
            onClose: this.onDropDownClose,
            showDropdown,
            variation,
            popover,
            openSearchModal,
        };

        const hawksearchHeaderProps = {
            setOpenSuggestions: this.setOpenSuggestions,
            openSuggestions: this.state.openSuggestions,
            setSearchKey: this.setSearchKey,
            searchKey,
            setInputFocused: this.setInputFocused,
            inputFocused,
            searchUrl: `${process.env.BASENAME}/sitesearch`,
            searchQueryStringKey: 'keyword',
            onInputFocusOut: () => this.setInputFocused(false),
            // onInputHover: () => this.setState({  })
        };

        const isHawksearch = variation === 'hawksearch';

        if (isHawksearch) {
            headerProps = {
                ...headerProps,
                ...hawksearchHeaderProps,
            };
        }
        //for server use standard device rather than custom breakpoints
        const isUnifiedSearchDesktop =
            !width || isServerCalc ? device === 'desktop' : unifiedSearchDevice === 'desktop';
        const mobileDrawerContainerClass = isUnifiedSearchDesktop
            ? ''
            : ` header-mobile-drawer-${isMobileDrawerOpen ? 'open' : 'closed'}`;
        const headerContent = (
            <div
                className={`header-container ${isV2 ? ' v2' : ''}${inputFocused ? ' focused' : ''}${
                    variation ? ' ' + variation + '-header' : ''
                }${mobileDrawerContainerClass}`}
                style={{ zIndex }}
            >
                <div className="nav-bar-container">
                    <nav className="nav-bar" aria-label="Main Store Menu">
                        <div className="hp-logo-header">
                            <Link
                                to={logoLink}
                                className="hp-header-logo"
                                aria-label="HP Logo"
                                makeBasenameRelative={logoLink === '/'}
                            >
                                <Icon icon="icon-hp-logo" />
                            </Link>
                        </div>
                        {!openSearchModal && <SkipLinks />}
                        {isUnifiedSearchDesktop ? (
                            <HeaderDesktop {...headerProps} width={width} />
                        ) : (
                            <HeaderMobile {...headerProps} setMobileDrawerOpen={this.setMobileDrawerOpen} />
                        )}
                        <BotNav {...headerProps} />
                    </nav>
                </div>
            </div>
        );

        return (
            <div
                ref={this.containerRef}
                className={
                    'header-search-vp-container' +
                    (openSearchModal ? ` open-search-modal${isHawksearch ? ' autocomplete-modal-open' : ''}` : '')
                }
            >
                <ValueProp />
                {!hideUtilityNAV && <UtilityNavContainer isV2={isV2} />}
                {isHawksearch ? (
                    <SearchAutocompleteContainer>{headerContent}</SearchAutocompleteContainer>
                ) : (
                    headerContent
                )}
                <Overlay offset={this.containerRef?.current?.clientHeight} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { userData, headerFooter, slugInfo, router, siteConfig, proxyHost } = state;
    const { basename, enableNewRoutes } = siteConfig || {};
    const { location } = router || {};
    const { pathname } = location || {};
    const { components } = slugInfo || {};
    const { privateStoreHeader } = components || {};
    const pStoreID = getPStoreID(userData);
    const { width, device, customBreakpoints, isServerCalc } = state.ui;
    const { unifiedSearch } = customBreakpoints || {};
    //TODO: temp code to isolate v2 header / footer css
    const isV2 = enableNewRoutes || slugInfo.gql || pathname === `${basename}/sitesearch`;

    const pStoreHeader =
        (headerFooter &&
            headerFooter.etrHeader &&
            headerFooter.etrHeader[pStoreID] &&
            headerFooter.etrHeader[pStoreID]) ||
        privateStoreHeader;

    const skip = ['cartPreviewClassic', 'cartPreviewNew', null, undefined, 'undefined', 'searchModal'];
    const isOverlay = !skip.includes(headerFooter.popover);

    return {
        pStoreID,
        header: pStoreID ? getPrivateStoreHeader(headerFooter, pStoreID) : headerFooter.header,
        privateStoreHeader: pStoreHeader,
        width,
        device,
        unifiedSearchDevice: unifiedSearch,
        isServerCalc,
        popover: headerFooter.popover === POPOVER_KEY,
        openSearchModal: headerFooter.popover === 'searchModal',
        // TODO: revisit when classic header is removed
        isOverlay,
        overlayShade: headerFooter.overlayShade,
        isV2,
        proxyHost,
        basename,
        fetchedPstoreHeader: (components && components.hasOwnProperty('privateStoreHeader')) || pStoreHeader, // indicates that header GQL field has been resolved
    };
};

const mapDispatchToProps = dispatch => ({
    fetchHeader: () => dispatch(fetchHeaderFooterComponent(['header'])),
    showDropdown: show => dispatch(showPopover(show, true)),
});

export default withError(withWindowResize(connect(mapStateToProps, mapDispatchToProps)(Header)));
